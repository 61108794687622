import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgCover from "../../assets/images/hope-probe/ground-segment/cover.png"
import imgChart from "../../assets/images/hope-probe/ground-segment/chart.png"
import imgGround from "../../assets/images/hope-probe/ground-segment/ground-segment.jpeg"
import imgMoc from "../../assets/images/hope-probe/ground-segment/moc.png"
import imgMoc2 from "../../assets/images/hope-probe/ground-segment/moc-2.jpeg"
import imgMsf from "../../assets/images/hope-probe/ground-segment/msf.png"
import imgNavigation from "../../assets/images/hope-probe/ground-segment/navigation.png"
import imgAboutSectionOne from "../../assets/images/hope-probe/ground-segment/satellite.jpeg"

const GroundSegmentPage = () => (
  <Layout>
    <SEO title="Ground Segment" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgCover} width="1440" height="535" alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div className="mb5">
                  <h2 className="relative">
                  The Emirates Mars Mission <br/> Ground Segment
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  The EMM ground segment is composed of the ground network and its ground stations, navigation system, operations center, mission design, Science Data Centre (SDC), and Instrument Team Facilities (ITFs).                    
                  </p>
                  <p>
                  The operation centres are located at LASP and MBRSC (which also houses the SDC). Mission Operations Centre (MOC) is located at the MBRSC and the Mission Support Facility (MSF) is located at LASP. Telemetry is routed to both the MOC and MSF every contact, so that full flight data sets reside at each location. A single operations location has command authority as scheduled on a contact-by-contact basis, with the other location serving as a redundant ground capability. The ground network supports contacts as scheduled for each phase of the mission. 
                  </p>
                  <p>
                  The navigation team provides determined ephemeris, predicted ephemeris, and burns to maintain the orbit or trajectory. The ITF for each instrument is responsible for instrument builds and tests, as well as building a repository of engineering information supporting the instrument.
                  </p>
                </div>
                <div className="flex flex-column flex-row-ns mt5">
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgGround} alt=""/>
                        </div>
                        </div>
                    </div>  
                    <div className="w-100 w-60-ns pl5-ns z-index-10">
                        <div>
                            <h3>
                            Ground Station Communication Network                          
                            </h3>
                            <p className="mv4">
                            The EMM Project has selected NASA’s Deep Space Network (DSN) as the sole-source GSCN provider. Managed out of the Jet Propulsion Laboratory (JPL) in Pasadena, CA, the DSN is responsible for scheduling the station contacts, transmitting commands to the spacecraft, acquiring telemetry data from the spacecraft, and recording radiometric tracking data.                          
                            </p>
                        </div>
                    </div>
                </div>

                <div className="mb5">
                    <h3>
                     Mission Design
                    </h3>
                    <p className="mt4">
                      Mission Design is provided by Advanced Space in Boulder, CO. They are responsible for creating and maintaining the Reference Trajectory and Reference Manoeuvres and creating the Events List for science operations. They are also providing the sanity check to Navigation for all burns and provide the launch targets to the launch service provider.
                    </p>
                </div>
                <div className="flex flex-column flex-row-ns pv5" style={{maxWidth: "900px", marginLeft: "auto", marginRight: "auto"}}>
                    <div className="w-100 w-70-ns pr5-ns z-index-10">
                        <div>
                            <h3>
                            Navigation
                            </h3>
                            <p className="mv4">Located at KinetX Aerospace in Tempe, Arizona. Responsible for generating spacecraft ephemeris data (SPK SPICE kernels) from data provided by the Ground Network and performing trajectory, manoeuvre and collision avoidance analysis.</p>
                        </div>
                    </div>
                    <div className="w-100 w-30-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgNavigation} className="w-100" alt=""/>
                        </div>
                        </div>
                    </div> 
                </div>
                <div className="flex flex-column flex-row-ns mv5">
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgMoc2} alt=""/>
                        </div>
                        </div>
                    </div>  
                    <div className="w-100 w-60-ns pl5-ns z-index-10">
                        <div>
                            <h3>
                            Mission Operations Centre (MOC)
                            </h3>
                            <p className="mv4">
                            Located at the Mohammed Bin Rashid Space Centre (MBRSC) in Dubai. The MOC is responsible for spacecraft health and safety monitoring, spacecraft telemetry operations, spacecraft command sequence development, spacecraft/instrument command integration, mission planning support, instrument operations, and the initial science data processing. The MOC provides the primary means to control and monitor the spacecraft bus, being responsible for command sequencing builds and loads, vehicle housekeeping monitoring, trending and analysis routines. Spacecraft telemetry and commands will be processed and archived by the MOC. The MOC will also be responsible for all uplinks to the spacecraft through the Ground Station Communications Network (GSCN). 
                            </p>
                            <p>
                            The MOC will prepare and integrate instrument command sequences with the spacecraft sequences. The MOC will generate Level 0 science data files and deliver them to the SDC.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-column flex-row-ns pv5">
                     
                    <div className="w-100 w-60-ns pr5-ns z-index-10">
                        <div>
                            <h3>
                            Mission Support Facility (MSF)
                            </h3>
                            <p className="mv4">
                            Located at the Laboratory for Atmospheric and Space Physics (LASP) at the University of Colorado in Boulder, CO. The MSF is capable of performing all of the functions of the MOC with the exception of routine science operations planning and Level 0 science processing. The MSF has its own direct connections to the GSCN and can command the observatory under the direction and authority of the MOC. A small percentage of the station contacts will nominally be run from the MSF for facility and staff proficiency. 
                            </p>
                            <p>
                            The MSF is the permanent Integration & Test archive and houses the backup KinetX navigation computers. The real-time telemetry broadcast to the Instrument Team Facilities (ITFs) also originates from the MSF.
                            </p>
                        </div>
                    </div>
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgMsf} alt=""/>
                        </div>
                        </div>
                    </div> 
                </div>
                <div className="flex flex-column flex-row-ns mt5">
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgAboutSectionOne} alt=""/>
                        </div>
                        </div>
                    </div>  
                    <div className="w-100 w-60-ns pl5-ns z-index-10">
                        <div>
                            <h3>
                            Science Data Centre (SDC)
                            </h3>
                            <p className="mv4">
                            Located at MBRSC. The Science Data Centre (SDC) is responsible for generating Quicklook, Level 1, and Level 2 science data products, distributing science data to the entire EMM team and the science community, facilitating the discovery and usage of science data, storing all science data products for the duration of the mission, and creating an archive to store data beyond the end of the mission. Standard science products include Level 1 and Level 2 data products that will be disseminated within the EMM team or to the science community. The SDC will receive Level 0 science and ancillary data from the MOC. The ITFs will develop and maintain science data processing software to be run at the SDC on that Level 0 data to generate Quicklook, Level 1, and Level 2 science data products, as applicable. 
                            </p>
                        </div>
                    </div>
                </div>
                            <p>
                            The science data products that are created at the SDC will be stored by the SDC for the duration of the project and will be made available to the EMM team and the wider science community within the required timeframes. The SDC will develop and maintain interfaces for accessing science data, and basic data analysis and visualization tools, in close coordination with the EMM science team. These tools will be available to the EMM team via the SDC website. The SDC will create a public data archive and access system which will be transferred to MBRSC management at the end of the EMM mission, for long-term hosting of EMM data and for use with future missions.
                            </p>
                <div className="mv5">
                    <h3>
                        Instrument Team Facilities (ITF)
                    </h3>
                    <p className="mv4">
                        The operational functions and capabilities of the ITF that it uses OASIS-CC for Command and Control, Quarterly Trending Reports: Housekeeping and Telemetry. It also maintains calibration of data products, manages instrument data stores and parameters, and maintains command effect. The ITF provides sequence of commands for I&T operations and capabilities. Data Processing Software. For the data processing software it provides SDC with software, documentation, & calibration data to generate Quicklook and Level 1 & 2 science data products. Authorizes release of science data products to science community.
                    </p>
                </div>
            </div>
          </div>  
      </section>
    </main>
  </Layout>
)

export default GroundSegmentPage
